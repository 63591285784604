import React from 'react';

import Listing from '../components/Listing';
import Seo from '../components/seo';

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <Listing />
  </>
);

export default IndexPage;
