import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ExpandIcon from '../../../assets/icons/expand.svg';
import $ from '../../../styles/global';
import Utils from '../../../utils';
import { updateFilter } from '../../../actions';

const Container = styled.div`
  margin-top: ${$.layout().margin3}px;
  user-select: none;
`;

const DropdownTitle = styled.div`
  ${({ expand }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${$.layout().margin5}px;

  & > * {
    transition: all 0.25s ${$.easingFn.standard};
  }

  & > svg {
    width: 20px;
    height: 20px;
    fill: ${$.color.white};
    transform: rotate(${expand ? '0' : '180deg'});
  }

  & > span {
    font-size: 15px;
    font-family: "SF Pro Bold";
    color: ${$.color.white};
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      cursor: pointer;

      & > span {
        color: ${$.color.lightgray1};
      }

      & > svg {
        fill: ${$.color.lightgray1};
      }
    }
  }
  // #endregion
`}
`;

const Checkboxes = styled.div`
  ${({ expand }) => `
  display: ${expand ? 'block' : 'none'};
  & > * {
    &:not(:last-child) {
      margin-bottom: ${$.layout().margin5}px;
    }
  }
`}
`;

const Checkbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  & > input[type='checkbox'] {
    margin-right: ${$.layout().margin5}px;
    appearance: none;
    align-self: flex-start;
    background-color: ${$.color.white};
    width: 14px;
    min-width: 14px;
    height: 14px;
    border-radius: 3px;

    &:focus {
      outline: none;
    }

    &:checked {
      background-color: ${$.color.black2};
      // The tick is a HTML entity. Since we have set the appearance to none,
      // we need to redo the entire styling of the checkbox again!
      & :after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='1 1 23 23'%3E%3Cpolyline points='20 6 9 17 4 12' fill='none' stroke='white' stroke-width='4px' /%3E%3C/svg%3E");
      }
    }
  }

  & > span {
    color: ${$.color.white};
    font-size: 15px;
    line-height: 16px;
  }
`;

const CheckboxSection = ({ title, list, type }) => {
  const [expand, setExpand] = useState(true);
  const [updateFilterSelection] = Utils.useActions([updateFilter]);
  const data = useSelector(({ filterData }) => filterData);

  return (
    <Container>
      <DropdownTitle
        onClick={() => {
          setExpand((prev) => !prev);
        }}
        expand={expand}
      >
        <span>{title}</span>
        <ExpandIcon />
      </DropdownTitle>
      <Checkboxes expand={expand}>
        {list.map(({ text, value, key }) => (
          <Checkbox
            key={key}
            onClick={() => {
              const val = data[type]?.[value];

              updateFilterSelection({
                type,
                enum: value,
                value: !val,
              });
            }}
          >
            <input type="checkbox" checked={!!data[type]?.[value]} readOnly />
            <span>{text}</span>
          </Checkbox>
        ))}
      </Checkboxes>
    </Container>
  );
};

CheckboxSection.defaultProps = {
  title: '',
  list: [],
  type: '',
};

CheckboxSection.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
};

export default CheckboxSection;
