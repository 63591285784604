import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Fuse from 'fuse.js';

import Card from './Card';
import $ from '../../../styles/global';
import SearchBar from './SearchBar';
import SideBarFilter from './SideBarFilter';
import Utils from '../../../utils';

const Container = styled.div``;

const JobListing = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 20px;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
  // #endregion
`;

const Status = styled.div``;

/**
 * Filters job listing with a combination of 3 categories:
 * Job Type, Work Experience, Job Categories
 * Uses OR instead of AND.
 */
const filteredListingWithFilterSelection = ({ jobData, filterData }) => {
  let filteredResults = [...jobData];

  const filterJobByFilterSelection = (selectedFilter) => {
    if (Object.keys(filterData[selectedFilter]).length === 0) {
      return filteredResults;
    }

    return filteredResults.filter(({ type, category, workExperience }) => {
      const selectedFilterChoices = Object.keys(filterData[selectedFilter]);
      let match = false;
      let matchingJobField;

      switch (selectedFilter) {
        case 'jobType':
          matchingJobField = type;
          break;
        case 'jobCategory':
          matchingJobField = category;
          break;
        case 'workExperience':
          matchingJobField = workExperience;
          break;
        default:
          break;
      }

      selectedFilterChoices.forEach((selectedFilterChoice) => {
        if (
          filterData[selectedFilter][selectedFilterChoice] === true &&
          matchingJobField === selectedFilterChoice &&
          match === false
        ) {
          match = true;
        }
      });

      return match;
    });
  };

  filteredResults = filterJobByFilterSelection('jobType');
  filteredResults = filterJobByFilterSelection('jobCategory');
  filteredResults = filterJobByFilterSelection('workExperience');

  return filteredResults;
};

/**
 * Filters from search term AFTER going through filter selection.
 *
 * Returns [{ matches: 0 }] if no match found.
 * This is an indicator to return a "No matches found" message.
 */
const filteredListingWithSearchTerm = (jobData, searchTerm) => {
  if (searchTerm === '') {
    if (jobData.length === 0) {
      return [{ matches: 0 }];
    }

    return jobData;
  }

  const fuse = new Fuse([...jobData], {
    keys: ['title', 'company.name'],
  });

  const filteredResults = fuse
    .search(searchTerm)
    .map(({ item }) => ({ ...item }));

  if (filteredResults.length === 0) {
    return [{ matches: 0 }];
  }

  return filteredResults;
};

const Cards = () => {
  const data = useSelector(({ jobData, filterData }) => ({
    jobData,
    filterData,
  }));
  const filteredJobs =
    data.jobData.length > 0
      ? filteredListingWithSearchTerm(
          filteredListingWithFilterSelection(data),
          data.filterData.searchTerm
        )
      : [];

  return (
    <Container>
      <SideBarFilter />
      <SearchBar />
      <JobListing>
        {data.jobData.length === 0 ? (
          <Status>Loading...</Status>
        ) : (
          filteredJobs.map(
            ({
              id,
              title,
              type,
              company,
              category,
              editingCompany,
              workExperience,
              createdAt = '',
              updatedAt = '',
              location,
              matches,
            }) => {
              const { lastCreated } = Utils.translateJobTimestampToString({
                createdAt,
                updatedAt,
              });

              if (typeof matches !== 'undefined') {
                return <Status key="status_0">No matches found.</Status>;
              }

              return (
                <Card
                  key={id}
                  jobId={id}
                  jobTitle={title}
                  editingCompany={editingCompany}
                  company={company}
                  jobType={Utils.getEnumText({
                    type: 'jobType',
                    value: type,
                  })}
                  jobCategory={Utils.getEnumText({
                    type: 'jobCategory',
                    value: category,
                  })}
                  workExperience={Utils.getEnumText({
                    type: 'workExperience',
                    value: workExperience,
                  })}
                  lastCreated={lastCreated}
                  location={location}
                />
              );
            }
          )
        )}
      </JobListing>
    </Container>
  );
};

export default Cards;
