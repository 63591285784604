import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import { deleteJobGQL } from '../../../apollo/mutations';
import { openModal, deleteJob } from '../../../actions';
import LabelIcon from '../../../assets/icons/label.svg';
import MoneyIcon from '../../../assets/icons/money.svg';
import LocationIcon from '../../../assets/icons/location.svg';
import BriefcaseIcon from '../../../assets/icons/briefcase.svg';
import TrashIcon from '../../../assets/icons/trash.svg';
import TimeIcon from '../../../assets/icons/time.svg';

const DeleteButton = styled.div`
  position: absolute;
  right: ${$.layout().padding3}px;
  display: none;

  & > svg {
    width: 22px;
    height: 26px;
    fill: ${$.color.black2};
    transition: all 0.25s ${$.easingFn.standard};
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      cursor: pointer;
      & > svg {
        fill: ${$.color.gray2};
      }
    }
  }
  // #endregion
`;

const Container = styled.div`
  border: 2px solid ${$.color.lightgray1};
  border-radius: 10px;
  padding: ${$.layout().padding4}px ${$.layout().padding3}px;
  background-color: ${$.color.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      ${DeleteButton} {
        display: block;
      }
    }
  }
  // #endregion
`;

const JobTitle = styled(Link)`
  font-family: 'SF Pro Bold';
  width: 85%;
  font-size: 17px;
  margin-bottom: ${$.layout().margin5}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: ${$.color.black2};
  transition: color 0.25s ${$.easingFn.standard};

  &:visited {
    color: ${$.color.black2};
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      color: ${$.color.blue};
      cursor: pointer;
    }
  }
  // #endregion
`;

const Info = styled.div`
  flex-grow: 1;
`;

const Company = styled.a`
  margin-bottom: ${$.layout().margin3}px;
  font-size: 16px;
  font-family: SF Pro Bold;
  color: ${$.color.blue};

  &:visited {
    color: ${$.color.blue};
  }
`;

const Detail = styled.div`
  margin-bottom: ${$.layout().margin5}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  & > span {
    line-height: 1.3em;
    font-size: 15px;
    word-break: break-word;
    width: calc(100% - ${$.layout().margin5}px - 20px);
  }

  & > svg {
    width: 20px;
    height: 20px;
    position: relative;
    top: -2px;
    fill: ${$.color.black2};
    margin-right: ${$.layout().margin5}px;
    align-self: flex-start;
  }
`;

const LastCreated = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${$.layout().margin4}px;

  & > span {
    color: ${$.color.gray2};
    font-size: 14px;
  }

  & > svg {
    width: 18px;
    height: 18px;
    fill: ${$.color.gray2};
    margin-right: ${$.layout().margin5}px;
  }
`;

const Card = ({
  jobId,
  jobTitle,
  company,
  jobCategory,
  jobType,
  location,
  editingCompany,
  workExperience,
  lastCreated,
}) => {
  const data = useSelector(({ userData }) => userData);
  const [openDeleteModalBox, deleteOneJob] = Utils.useActions([
    openModal,
    deleteJob,
  ]);
  const [deleteOneJobPosting] = useMutation(deleteJobGQL, {
    onCompleted: (results) => {
      if (results === null) {
        toast.error('An error occurred. Failed to delete job posting.');
      } else {
        deleteOneJob(results.deleteJob.id);
        toast.success('Job posting successfully deleted.');
      }
    },
    onError: () => {
      toast.error('An error occurred. Failed to delete job posting.');
    },
  });
  const showDeleteButton = () => {
    const isAdmin = data.groups.indexOf('admin') > -1;
    const isHr = data.groups.indexOf('hr') > -1;
    const isClientAndSameCompany =
      data.groups.indexOf('client') > -1 &&
      data.editingCompany.indexOf(editingCompany) > -1;

    if (isAdmin) {
      return true;
    }

    if (isClientAndSameCompany) {
      return true;
    }

    if (isHr) {
      return false;
    }

    return false;
  };

  return (
    <Container>
      <JobTitle to={`/job/?id=${jobId}`}>
        <span>{jobTitle}</span>
      </JobTitle>
      {showDeleteButton() && (
        <DeleteButton
          title="Delete job"
          onClick={() => {
            openDeleteModalBox({
              title: 'Delete job posting',
              message: `You are about to delete job posting for the position ${jobTitle} by ${company.name}. This action is irreversible. Would you like to proceed?`,
              options: [
                {
                  text: 'Cancel',
                  color: $.color.white,
                  callback: () => {},
                },
                {
                  text: 'Yes',
                  color: $.color.green,
                  callback: () => {
                    deleteOneJobPosting({ variables: { id: jobId } });
                  },
                },
              ],
            });
          }}
        >
          <TrashIcon />
        </DeleteButton>
      )}
      <Company href={company.url} target="_blank" rel="noopener noreferrer">
        {company.name}
      </Company>
      <Info>
        <Detail>
          <LabelIcon />
          <span>{jobCategory}</span>
        </Detail>
        <Detail>
          <MoneyIcon />
          <span>{jobType}</span>
        </Detail>
        <Detail>
          <LocationIcon />
          <span>{`${location.city}, ${location.country}`}</span>
        </Detail>
        <Detail>
          <BriefcaseIcon />
          <span>{workExperience}</span>
        </Detail>
      </Info>
      <LastCreated>
        <TimeIcon />
        <span>{lastCreated}</span>
      </LastCreated>
    </Container>
  );
};

Card.defaultProps = {
  jobId: '',
  jobTitle: '',
  jobCategory: '',
  jobType: '',
  editingCompany: '',
  location: { city: '', country: '' },
  workExperience: '',
  company: { name: '', url: '' },
  lastCreated: '',
};

Card.propTypes = {
  editingCompany: PropTypes.string,
  jobId: PropTypes.string,
  jobTitle: PropTypes.string,
  company: PropTypes.objectOf(PropTypes.string),
  jobCategory: PropTypes.string,
  lastCreated: PropTypes.string,
  jobType: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.string),
  workExperience: PropTypes.string,
};

export default Card;
