import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import MagnifierIcon from '../../../assets/icons/magnifier.svg';
import FilterIcon from '../../../assets/icons/filter.svg';
import { openSideBarFilter, setSearchTerm } from '../../../actions';

const Container = styled.div`
  margin-bottom: ${$.layout().margin3}px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Bar = styled.input`
  width: calc(100% - ${$.layout().padding4 + 50}px);
  border: none;
  outline: none;
  background-color: ${$.color.lightgray1};
  border-radius: 10px;
  padding: ${$.layout().padding4}px ${$.layout().padding4}px
    ${$.layout().padding4}px 50px;
  line-height: 16px;
  color: ${$.color.black2};
`;

const StyledMagnifierIcon = styled(MagnifierIcon)`
  position: absolute;
  fill: ${$.color.gray2};
  width: 25px;
  height: 25px;
  left: 15px;
  top: 13px;
`;

const FilterButton = styled.div`
  display: none;
  margin-left: ${$.layout().margin4}px;

  & > svg {
    width: 30px;
    height: 30px;
    fill: ${$.color.black2};
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: block;
  }
  // #endregion
`;

const SearchBar = () => {
  const { openSideBarFilter: show, searchTerm: searchTermValue } = useSelector(
    ({ filterData }) => filterData
  );
  const [showSideBarFilter, searchTerm] = Utils.useActions([
    openSideBarFilter,
    setSearchTerm,
  ]);

  return (
    <Container>
      <StyledMagnifierIcon />
      <Bar
        value={searchTermValue || ''}
        placeholder="Job title or company"
        onChange={(e) => {
          const val = e.target.value;

          searchTerm(val);
        }}
      />
      <FilterButton
        title="Filter"
        onClick={() => {
          showSideBarFilter(!show);
        }}
      >
        <FilterIcon />
      </FilterButton>
    </Container>
  );
};

export default SearchBar;
