import React, { useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import $ from '../../../../styles/global';
import Button from '../../../Button';
import Utils from '../../../../utils';
import { resetFilter, openSideBarFilter } from '../../../../actions';
import CheckboxSection from './CheckboxSection';
import items from '../../items.json';

const Background = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity 0.35s ${$.easingFn.standard};
  background-color: rgba(44, 49, 59, 0.67);
  top: 0;
  left: 0;

  &.show {
    pointer-events: initial;
    opacity: 1;
  }
`;

const SideBar = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 2;
  width: calc(90vw - ${$.layout().padding3 * 2}px);
  right: 0;
  transform: translateX(100%);
  height: calc(100vh - ${$.layout().padding3 * 2}px);
  opacity: 0;
  overflow: auto;
  background-color: ${$.color.white};
  transition: transform 0.4s ${$.easingFn.standard},
    opacity 0.5s ${$.easingFn.standard};
  top: 0;
  padding: ${$.layout().padding3}px;
  display: flex;
  flex-direction: column;

  &.show {
    transform: translateX(0);
    pointer-events: initial;
    opacity: 1;
  }
`;

const ChoicesContainer = styled.div`
  overflow: scroll;
  height: 72%;
  margin-bottom: ${$.layout().margin4}px;
`;

const Title = styled.div`
  margin-bottom: ${$.layout().margin3}px;
  font-size: 17px;
  line-height: 17px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > * {
    max-width: 90px !important;
    font-size: 15px;

    &:first-child {
      margin-right: ${$.layout().margin5}px;
      background-color: ${$.color.white};
    }
    &:last-child {
      background-color: ${$.color.orange};
    }
  }
`;

const SideBarFilter = () => {
  const containerRef = useRef(null);
  const [resetFilterSelection, showSideBarFilter] = Utils.useActions([
    resetFilter,
    openSideBarFilter,
  ]);
  const { openSideBarFilter: show } = useSelector(
    ({ filterData }) => filterData
  );

  Utils.useOutsideClick([containerRef], () => {
    if (containerRef.current.className.indexOf('show') > -1) {
      showSideBarFilter(false);
    }
  });

  return (
    <>
      <Background className={show ? 'show' : ''} />
      <SideBar ref={containerRef} className={show ? 'show' : ''}>
        <Title>Filter your search</Title>
        <ChoicesContainer>
          {items.map(({ type, title, choices }, index) => (
            <CheckboxSection
              title={title}
              key={`checkbox_${type}_${index}`}
              type={type}
              list={Utils.addKeys(choices)}
            />
          ))}
        </ChoicesContainer>
        <Buttons>
          <Button
            onClick={() => {
              showSideBarFilter(!show);
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              resetFilterSelection();
            }}
          >
            Reset
          </Button>
        </Buttons>
      </SideBar>
    </>
  );
};

export default SideBarFilter;
