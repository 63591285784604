import React from 'react';
import styled from 'styled-components';

import $ from '../../../styles/global';
import TrashIcon from '../../../assets/icons/trash.svg';
import CheckboxSection from './CheckboxSection';
import Utils from '../../../utils';
import { resetFilter } from '../../../actions';
import items from '../items.json';

const Container = styled.div`
  border-radius: 8px;
  padding: ${$.layout().padding3}px;
  background-color: ${$.color.orange};
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${$.color.white};
`;

const ResetFilterButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      cursor: pointer;
      & > svg {
        fill: ${$.color.lightgray1};
      }
      & > span {
        color: ${$.color.lightgray1};
      }
    }
  }
  // #endregion

  & > * {
    transition: all 0.25s ${$.easingFn.standard};
  }

  & > svg {
    width: 18px;
    height: 18px;
    margin-right: ${$.layout().margin5}px;
    fill: ${$.color.white};
  }

  & > span {
    line-height: 15px;
    font-size: 15px;
    color: ${$.color.white};
  }
`;

const Filter = () => {
  const [resetFilterSelection] = Utils.useActions([resetFilter]);

  return (
    <Container>
      <Title>
        <span>Filter your search</span>
        <ResetFilterButton
          title="Reset filter"
          onClick={() => {
            resetFilterSelection();
          }}
        >
          <TrashIcon />
          <span>Reset</span>
        </ResetFilterButton>
      </Title>
      {Utils.addKeys(items).map(({ key, type, title, choices }) => (
        <CheckboxSection
          title={title}
          key={key}
          type={type}
          list={Utils.addKeys(choices)}
        />
      ))}
      {/* Last Updated section needed */}
    </Container>
  );
};

export default Filter;
