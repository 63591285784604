import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';

import { getAllJobGQL } from '../../apollo/queries';
import { updateJobs } from '../../actions';
import Utils from '../../utils';
import Filter from './Filter';
import Cards from './Cards';
import $ from '../../styles/global';

const Section = styled.section``;

const Container = styled.div`
  padding: ${$.layout().padding3}px 0;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    & > * {
      &:first-child {
        margin-right: 50px;
        width: 25%;
      }
      &:last-child {
        width: 75%;
      }
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    & > * {
      &:first-child {
        margin-right: 35px;
        width: calc(25% - 35px);
      }
      &:last-child {
        width: 75%;
      }
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    & > * {
      &:first-child {
        display: none;
      }
      &:last-child {
        width: 100%;
      }
    }
  }
  // #endregion
`;

const Listing = () => {
  const [updateJobListing] = Utils.useActions([updateJobs]);
  const [getAllJob] = useLazyQuery(getAllJobGQL, {
    onCompleted: ({ allJob: result }) => {
      updateJobListing(result);
    },
  });

  useEffect(() => {
    getAllJob();
  }, []);

  return (
    <Section>
      <Container>
        <Filter />
        <Cards />
      </Container>
    </Section>
  );
};

export default Listing;
